import React from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';

import invoiz from '../../../services/invoiz.service';

const MenuItemComponent = (props) => {
  const { title, name, url, icon, active, submenuVisible } = props;
  const iconClass = `icon icon-${icon}`;
  const activeClass = active ? 'menuItem-active' : '';
  const submenuVisibleClass = submenuVisible ? 'menuItem-notFocused' : '';
  const className = `menuItem ${iconClass} ${activeClass} ${submenuVisibleClass}`;

  const navigateToPage = (url, evt) => {
    invoiz.trigger('triggerSubmenuHide');

    isSubmenuClick(evt);
    invoiz.router.navigate(url);
  };

  const isSubmenuClick = (evt) => {
    return (
      evt &&
      evt.nativeEvent.target &&
      $(evt.nativeEvent.target).closest('.submenu').length > 0
    );
  };

  return (
    <a
      className={className}
      data-href={url}
      data-qs-id={`global-menu-item-${name}`}
      onClick={(e) => navigateToPage(url, e)}
    >
      {/* {title} */}
      {/* {title} */}
      <span className='collapsed-title'>{title}</span>
    </a>
  );
};

MenuItemComponent.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  icon: PropTypes.string,
  active: PropTypes.bool,
  submenuVisible: PropTypes.bool,
  resourceKey: PropTypes.string,
};

MenuItemComponent.defaultProps = {
  title: '',
  url: '',
  icon: '',
  active: false,
  submenuVisible: false,
  resourceKey: '',
};

export default MenuItemComponent;
