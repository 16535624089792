import React from 'react';
import invoiz from '../../services/invoiz.service';
import config from '../../../config';
import { Provider } from 'react-redux';
import Menu from './components/menu.component';
import store from '../../redux/store';

class NavMainComponent extends React.Component {
  constructor(props) {
    super(props);
    if (invoiz.user.role && invoiz.user.permission) {
      let menuItem = {};
      let permission = invoiz.user.permission;

      let articleSubmenuItems = [];
      if (
        permission.devices &&
        (permission.devices.create ||
          permission.devices.delete ||
          permission.devices.edit ||
          permission.devices.read)
      ) {
        articleSubmenuItems.push({
          name: 'Devices',
          title: 'Geräte',
          url: '/article/devices',
          mainSubmenuItem: 'articles',
          resourceKey: 'Devices',
          icon: 'device',
        });
      }
      if (
        permission.accessories &&
        (permission.accessories.create ||
          permission.accessories.delete ||
          permission.accessories.edit ||
          permission.accessories.read)
      ) {
        articleSubmenuItems.push({
          name: 'Accessories',
          title: 'Zubehör',
          url: '/article/accessories',
          mainSubmenuItem: 'articles',
          resourceKey: 'Accessories',
          icon: 'accessories',
        });
      }
      menuItem = {
        name: 'articles',
        icon: 'radiovector',
        title: 'Hörgeräte',
        url: '',
        resourceKey: 'article',
        submenuItems: articleSubmenuItems,
      };
      if (articleSubmenuItems.length > 0) {
        config.menuItemsData.push(menuItem);
      }

      let contactsSubmenuItems = [];

      if (
        permission.patient &&
        (permission.patient.create ||
          permission.patient.delete ||
          permission.patient.edit ||
          permission.patient.read)
      ) {
        contactsSubmenuItems.push({
          name: 'ContactsPatient',
          title: 'Patienten',
          url: '/contact/patient',
          mainSubmenuItem: 'contacts',
          resourceKey: 'ContactsPatient',
          icon: 'device',
        });
      }
      if (
        permission.hno &&
        (permission.hno.create ||
          permission.hno.delete ||
          permission.hno.edit ||
          permission.hno.read)
      ) {
        contactsSubmenuItems.push({
          name: 'ENTPractice',
          title: 'HNO',
          url: '/contact/ent-practice',
          mainSubmenuItem: 'contacts',
          resourceKey: 'ENTPractice',
          icon: 'device',
        });
      }

      if (
        permission.healthInsurances &&
        (permission.healthInsurances.create ||
          permission.healthInsurances.delete ||
          permission.healthInsurances.edit ||
          permission.healthInsurances.read)
      ) {
        contactsSubmenuItems.push({
          name: 'HealthInsurance',
          title: 'Krankenkassen',
          url: '/contact/health-insurance',
          mainSubmenuItem: 'contacts',
          resourceKey: 'HealthInsurance',
          icon: 'health',
        });
      }

      if (
        permission.hsb &&
        (permission.hsb.create ||
          permission.hsb.delete ||
          permission.hsb.edit ||
          permission.hsb.read)
      ) {
        contactsSubmenuItems.push({
          name: 'HearingAidSystemAdvisor',
          title: 'HSB',
          url: '/contact/hearing-aid-system-advisor',
          mainSubmenuItem: 'contacts',
          resourceKey: 'HearingAidSystemAdvisor',
          icon: 'radiovector',
        });
      }

      menuItem = {
        name: 'contacts',
        icon: 'profile',
        title: 'Kontakte',
        url: '',
        resourceKey: 'contact',
        submenuItems: contactsSubmenuItems,
      };
      if (contactsSubmenuItems.length > 0) {
        config.menuItemsData.push(menuItem);
      }

      if (
        permission.supplies &&
        (permission.supplies.create ||
          permission.supplies.delete ||
          permission.supplies.edit ||
          permission.supplies.read)
      ) {
        config.menuItemsData.push({
          name: 'supplies',
          icon: 'document',
          title: 'Vorgänge',
          url: '/supplies',
          resourceKey: 'supplies',
          submenuItems: [
            // {
            //   name: 'supplies',
            //   title: 'Vorgänge',
            //   url: '/supplies',
            //   mainSubmenuItem: 'supplies',
            //   resourceKey: 'supplies',
            //   icon: '',
            // },
          ],
        });
      }

      let basedatasSubmenuItems = [];
      if (
        permission.users &&
        (permission.users.create ||
          permission.users.delete ||
          permission.users.edit ||
          permission.users.read)
      ) {
        basedatasSubmenuItems.push({
          name: 'Users',
          title: 'Nutzer',
          url: '/base-data/users',
          mainSubmenuItem: 'basedatas',
          resourceKey: 'Users',
          icon: 'users',
        });
      }
      if (
        permission.roles &&
        (permission.roles.create ||
          permission.roles.delete ||
          permission.roles.edit ||
          permission.roles.read)
      ) {
        basedatasSubmenuItems.push({
          name: 'Roles',
          title: 'Rollen',
          url: '/base-data/roles',
          mainSubmenuItem: 'basedatas',
          resourceKey: 'Roles',
          icon: 'unlock',
        });
      }
      menuItem = {
        name: 'basedatas',
        icon: 'administration',
        title: 'Verwaltung',
        url: '',
        resourceKey: 'basedata',
        submenuItems: basedatasSubmenuItems,
      };
      if (basedatasSubmenuItems.length > 0) {
        config.menuItemsData.push(menuItem);
      }

      let faqSubmenuItems = [];

      if (
        permission.faq &&
        (permission.faq.create ||
          permission.faq.delete ||
          permission.faq.edit ||
          permission.faq.read)
      ) {
        faqSubmenuItems.push({
          name: 'faqList',
          title: 'FAQ',
          url: '/faq/faq-list',
          mainSubmenuItem: 'faqs',
          resourceKey: 'faqList',
          icon: '',
        });
      }

      if (
        permission.helpDocument &&
        (permission.helpDocument.create ||
          permission.helpDocument.delete ||
          permission.helpDocument.edit ||
          permission.helpDocument.read)
      ) {
        faqSubmenuItems.push({
          name: 'document',
          title: 'Dokumente',
          url: '/faq/document',
          mainSubmenuItem: 'faqs',
          resourceKey: 'document',
          icon: '',
        });
      }

      if (
        permission.faq &&
        (permission.faq.create ||
          permission.faq.delete ||
          permission.faq.edit ||
          permission.faq.read)
      ) {
        config.menuItemsData.push({
          name: 'faqs',
          icon: 'help-circle',
          title: 'Hilfe',
          url: '/faq',
          resourceKey: 'faq',
          submenuItems: faqSubmenuItems,
        });
      }
    }

    config.menuItemsData = config.menuItemsData.reduce((items, menu) => {
      if (items.some((i) => i.name === menu.name)) {
        return items;
      } else {
        return [...items, menu];
      }
    }, []);

    this.state = {
      activeItem: '',
      activeSubmenuItem: '',
      menuItems: JSON.parse(JSON.stringify(config.menuItemsData)),
    };

    invoiz.on('menuItemChanged', ({ menuItem, submenuItem, path }) => {
      let activeItem = '';
      let activeSubmenuItem = '';
      let activeMenuItems = null;

      if (menuItem) {
        activeMenuItems = this.updateMenuActiveItems(
          menuItem,
          submenuItem,
          path
        );
        activeItem = activeMenuItems.activeItem;
        activeSubmenuItem = activeMenuItems.activeSubmenuItem;
      }

      if (
        activeItem?.toLocaleLowerCase() === 'dashboard' ||
        activeItem === 'supplies'
      ) {
        setTimeout(() => {
          const els = document.querySelectorAll('.layout-nav, .topbar-wrapper');
          els.forEach((el) => el.classList.add('hide-submenu'));
        }, 0);
      } else {
        setTimeout(() => {
          const els = document.querySelectorAll('.layout-nav, .topbar-wrapper');
          els.forEach((el) => el.classList.remove('hide-submenu'));
        }, 0);
      }

      setTimeout(() => {
        this.setState({
          activeItem,
          activeSubmenuItem,
        });
      });
    });
  }

  updateMenuActiveItems(itemName, submenuItemName, path) {
    const { menuItems } = this.state;
    let activeItem = null;
    let activeSubmenuItem = null;

    menuItems.forEach((menuItem) => {
      menuItem.active = menuItem.name === itemName;

      if (menuItem.active) {
        activeItem = menuItem.name;
        if (menuItem.submenuItems && menuItem.submenuItems.length > 0) {
          menuItem.submenuItems.forEach((submenuItem) => {
            if (submenuItem.name === 'supplies') submenuItem.active = true;
            else if (
              submenuItem.name === 'dashboard' ||
              submenuItem.name === 'Dashboard'
            )
              submenuItem.active = true;
            else if (submenuItem.url === path) submenuItem.active = true;
            else submenuItem.active = submenuItem.name === submenuItemName;

            if (submenuItem.active) {
              activeSubmenuItem = submenuItem.name;
            }
          });
        }
      }
    });

    return {
      activeItem,
      activeSubmenuItem,
    };
  }

  render() {
    const { activeItem, activeSubmenuItem } = this.state;
    return (
      <Provider store={store}>
        <Menu activeItem={activeItem} activeSubmenuItem={activeSubmenuItem} />
      </Provider>
    );
  }
}

export default NavMainComponent;
