import invoiz from './services/invoiz.service';
import React, { Suspense } from 'react';
import routes from '../routes';
import { RouteTypes } from './helpers/constants';
import { Router, withRouter, Route, Switch } from 'react-router-dom';
import { PublicRoute, PrivateRoute } from './helpers/routes';
import history from './helpers/history';
import { Provider } from 'react-redux';
import store from './redux/store';
import NavMainComponent from './shared/nav-main/nav-main.component';

import { fetchLanguageFile } from './redux/ducks/language/lang';

const fallback = (
  <div className='loader-wrapper'>
    <img src='../assets/images/audito.png' style={{ width: '250px' }} />
    <span className='loader_spinner loader_spinner_lg'></span>
  </div>
);

class PageContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasFooterContent: false,
    };
  }

  render() {
    const isLoggedIn = invoiz.user.loggedIn;
    const { hasFooterContent } = this.state;
    const layoutClass = 'layout layout-nav';

    return (
      <div className={`layout-wrapper ${hasFooterContent ? 'has-footer' : ''}`}>
        <div className={`${layoutClass}`}>
          {isLoggedIn && !this.props.location.pathname.match('account') ? (
            <NavMainComponent />
          ) : null}
          <section>
            <Suspense
              fallback={
                <div className='loader-wrapper'>
                  <span className='loader_spinner loader_spinner_lg'></span>
                </div>
              }
            >
              <Switch>
                {routes.map((route, index) => {
                  switch (route.type) {
                    case RouteTypes.ROUTE:
                      return (
                        <Route
                          key={index}
                          path={route.path}
                          exact={route.exact}
                          component={route.component}
                        />
                      );

                    case RouteTypes.PRIVATE:
                      return (
                        <PrivateRoute
                          key={index}
                          path={route.path}
                          exact={route.exact}
                          list={route.list}
                          component={route.component}
                          title={route.title}
                          menuItem={route.menuItem}
                          submenuItem={route.submenuItem}
                          pageClass={route.pageClass}
                          resourceKey={route.resourceKey}
                        />
                      );

                    case RouteTypes.PUBLIC:
                      return (
                        <PublicRoute
                          key={index}
                          path={route.path}
                          exact={route.exact}
                          component={route.component}
                          title={route.title}
                          resourceKey={route.resourceKey}
                        />
                      );
                  }
                })}
              </Switch>
            </Suspense>
          </section>
        </div>
      </div>
    );
  }
}

const PageContainerWithRouter = withRouter(PageContainer);

class AppComponent extends React.Component {
  constructor(props) {
    super(props);
    store.dispatch(fetchLanguageFile('de'));
  }

  render() {
    return (
      <Suspense fallback={fallback}>
        <Router history={history} forceRefresh={true}>
          <Provider store={store}>
            <PageContainerWithRouter />
          </Provider>
        </Router>
      </Suspense>
    );
  }
}

export default AppComponent;
