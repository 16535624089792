const WebStorageKey = {
  API_ROOT: 'apiRoot',
  ADMIN_MODE_ACTIVE: 'adminModeActive',
  CACHE_KEY_LIST_SETTING: 'list_setting',
  IMPORT_API_ROOT: 'importApiRoot',
  RETURN_AFTER_LOGIN: 'returnAfterLogin',
  REGISTRATION_EMAIL: 'registrationEmail',
  REGISTRATION_EMAIL_PROVIDER: 'registrationEmailProvider',
  SELECTED_BANK_ACCOUNTS: 'selectedBankAccounts',
  SELECTED_BANK_ACCOUNTS_COCKPIT: 'selectedBankAccountsCockpit',
  AMAZON_REDIRECT_URL: 'amazonRedirectUrl',
  IS_AMAZON_REGISTRATION: 'isAmazonRegistration',
  CONTINUE_REGISTRATION: 'continueRegistration',
  GOOGLE_REGISTRATION_STARTED: 'googleRegistrationStarted',
  USER: 'user',
  LAST_LOGGED_IN_USER: 'lastLoggedInUser',
  UTM_PARAMETERS: 'utmParams',

  TIMETRACKING_LIST_SETTINGS: 'timetrackingListSettings',
  INVOICE_LIST_SETTINGS: 'invoiceListSettings',
  RECURRING_INVOICE_LIST_SETTINGS: 'recurringInvoiceListSettings',
  PROJECT_LIST_SETTINGS: 'projectListSettings',
  OFFER_LIST_SETTINGS: 'offerListSettings',
  PURCHASEORDER_LIST_SETTINGS: 'purchaseOrderListSettings',
  OFFER_IMPRESS_LIST_SETTINGS: 'offerImpressListSettings',
  CUSTOMER_LIST_SETTINGS: 'customerListSettings',
  CUSTOMER_HISTORY_LIST_SETTINGS: 'customerHistoryListSettings',
  ARTICLE_LIST_SETTINGS: 'articleListSettings',
  ARTICLE_HISTORY_LIST_SETTINGS: 'articleHistoryListSettings',
  EXPENSE_LIST_SETTINGS: 'expenseListSettings',
};

export default Object.freeze(WebStorageKey);
