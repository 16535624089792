import React from 'react';
import invoiz from '../services/invoiz.service';
import { Redirect, Route } from 'react-router-dom';

const ROUTE_DASHBOARD = '/dashboard';
const ROUTE_LOGIN = '/account/login';

export function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        invoiz.trigger('menuItemChanged', {
          menuItem: rest.menuItem,
          submenuItem: rest.submenuItem,
          path: rest.path,
        });
        const list = rest.list != undefined ? 'full_container' : '';
        return invoiz.user.loggedIn ? (
          <div className={`page main ${list} ${rest.pageClass || ''}`}>
            <Component {...props} />
          </div>
        ) : (
          <Redirect
            to={{
              pathname: ROUTE_LOGIN,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
}

export function PublicRoute({ component: Component, ...rest }) {
  localStorage.removeItem('user');

  const isAccountDelete =
    rest.computedMatch &&
    rest.computedMatch.path &&
    rest.computedMatch.path.indexOf('/account/forgot_password/') !== -1;

  const isAccountEmailChange =
    rest.computedMatch &&
    rest.computedMatch.path &&
    rest.computedMatch.path.indexOf('/account/create_password/') !== -1;

  invoiz.user.loggedIn = false;
  return (
    <Route
      {...rest}
      render={(props) =>
        !invoiz.user.loggedIn ||
        (invoiz.user.loggedIn && (isAccountDelete || isAccountEmailChange)) ? (
          <div className='page'>
            <Component {...props} />
          </div>
        ) : (
          <Redirect
            to={{
              pathname: ROUTE_DASHBOARD,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export class UnmatchedRoute extends React.Component {
  render() {
    return invoiz.user.loggedIn ? (
      <Redirect
        to={{
          pathname: ROUTE_DASHBOARD,
        }}
      />
    ) : (
      <Redirect
        to={{
          pathname: ROUTE_LOGIN,
        }}
      />
    );
  }
}
