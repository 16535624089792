import PropTypes from 'prop-types';
import invoiz from '../../../services/invoiz.service';

const SubmenuItemComponent = ({ title, url, active, name }) => {
  const activeClass = active ? 'submenuItem-active' : '';
  const className2 = `submenuItem  ${activeClass}`;

  const navigateToPage = (url) => {
    invoiz.router.navigate(url);
  };
  return (
    <li>
      <a
        className={className2}
        onClick={() => navigateToPage(url)}
        data-href={url}
        data-qs-id={`global-submenu-item-${name}`}
      >
        <span data-href={url} className='collapsed-title'>
          {title}
        </span>
      </a>
    </li>
  );
};

SubmenuItemComponent.propTypes = {
  url: PropTypes.string,
  active: PropTypes.bool,
  resourceKey: PropTypes.string,
};

SubmenuItemComponent.defaultProps = {
  url: '',
  active: false,
  resourceKey: '',
};

export default SubmenuItemComponent;
