import React from 'react';
import { RouteTypes } from './app/helpers/constants';
import { UnmatchedRoute } from './app/helpers/routes';
const ForgotPasswordWrapper = React.lazy(() =>
  import('./app/views/accounts/forgot-password.wrapper')
);
const CreatePasswordWrapper = React.lazy(() =>
  import('./app/views/accounts/create-password.wrapper')
);
const LoginWrapper = React.lazy(() =>
  import('./app/views/accounts/login.wrapper')
);
// const OverviewWrapper = React.lazy(() =>
//   import('./app/views/overview/overview.wrapper')
// );
const SiteListWrapper = React.lazy(() =>
  import('./app/views/site/site-list.wrapper')
);
const ENTPracticeNewWrapper = React.lazy(() =>
  import('./app/views/contact/ent-practice/ent-practice-new.wrapper')
);
const ENTPracticeDetailWrapper = React.lazy(() =>
  import('./app/views/contact/ent-practice/ent-practice-detail.wrapper')
);
const ENTPracticeEditWrapper = React.lazy(() =>
  import('./app/views/contact/ent-practice/ent-practice-edit.wrapper')
);
const ENTPracticeListWrapper = React.lazy(() =>
  import('./app/views/contact/ent-practice/ent-practice-list.wrapper')
);
const PatientListWrapper = React.lazy(() =>
  import('./app/views/contact/patient/patient-list.wrapper')
);
const HearingAidSystemAdvisorNewWrapper = React.lazy(() =>
  import(
    './app/views/contact/hearing-aid-system-advisor/hearing-aid-system-advisor-new.wrapper'
  )
);
const HearingAidSystemAdvisorDetailWrapper = React.lazy(() =>
  import(
    './app/views/contact/hearing-aid-system-advisor/hearing-aid-system-advisor-detail.wrapper'
  )
);
const HearingAidSystemAdvisorEditWrapper = React.lazy(() =>
  import(
    './app/views/contact/hearing-aid-system-advisor/hearing-aid-system-advisor-edit.wrapper'
  )
);
const HearingAidSystemAdvisorListWrapper = React.lazy(() =>
  import(
    './app/views/contact/hearing-aid-system-advisor/hearing-aid-system-advisor-list.wrapper'
  )
);
const HealthInsuranceNewWrapper = React.lazy(() =>
  import('./app/views/contact/health-insurance/health-insurance-new.wrapper')
);
const HealthInsuranceEditWrapper = React.lazy(() =>
  import('./app/views/contact/health-insurance/health-insurance-edit.wrapper')
);
const HealthInsuranceListWrapper = React.lazy(() =>
  import('./app/views/contact/health-insurance/health-insurance-list.wrapper')
);
const HealthInsuranceDetailWrapper = React.lazy(() =>
  import('./app/views/contact/health-insurance/health-insurance-detail.wrapper')
);

const DevicesNewWrapper = React.lazy(() =>
  import('./app/views/articles/devices/devices-new.wrapper')
);
const DevicesDetailWrapper = React.lazy(() =>
  import('./app/views/articles/devices/devices-detail.wrapper')
);
const DevicesEditWrapper = React.lazy(() =>
  import('./app/views/articles/devices/devices-edit.wrapper')
);
const DevicesListWrapper = React.lazy(() =>
  import('./app/views/articles/devices/devices-list.wrapper')
);

const AccessoriesNewWrapper = React.lazy(() =>
  import('./app/views/articles/accessories/accessories-new.wrapper')
);
const AccessoriesDetailWrapper = React.lazy(() =>
  import('./app/views/articles/accessories/accessories-detail.wrapper')
);
const AccessoriesEditWrapper = React.lazy(() =>
  import('./app/views/articles/accessories/accessories-edit.wrapper')
);
const AccessoriesListWrapper = React.lazy(() =>
  import('./app/views/articles/accessories/accessories-list.wrapper')
);
const UsersListWrapper = React.lazy(() =>
  import('./app/views/base-data/users/users-list.wrapper')
);
const RoleListWrapper = React.lazy(() =>
  import('./app/views/base-data/roles/role-list.wrapper')
);

const RolePermissionEditWrapper = React.lazy(() =>
  import('./app/views/base-data/roles/rolepermission-edit.wrapper')
);
const RolePermissionNewWrapper = React.lazy(() =>
  import('./app/views/base-data/roles/rolepermission-new.wrapper')
);

const SuppliesWrapper = React.lazy(() =>
  import('./app/views/supplies/supplies.wrapper')
);
const SuppliesListWrapper = React.lazy(() =>
  import('./app/views/supplies/supplies-list.wrapper')
);
const SuppliesPatientListWrapper = React.lazy(() =>
  import('./app/views/supplies/patient-list.wrapper')
);
const DocumentsWrapper = React.lazy(() =>
  import('./app/views/supplies/documents.wrapper')
);

const PatientNewWrapper = React.lazy(() =>
  import('./app/views/contact/patient/patient-new.wrapper')
);
const PatientDetailWrapper = React.lazy(() =>
  import('./app/views/contact/patient/patient-detail.wrapper')
);
const PatientEditWrapper = React.lazy(() =>
  import('./app/views/contact/patient/patient-edit.wrapper')
);
const DashboardListWrapper = React.lazy(() =>
  import('./app/views/Dashboard/DashboardList.wrapper')
);
const PdfView = React.lazy(() =>
  import('./app/views/supplies/PdfView.component')
);
const FAQWrapper = React.lazy(() => import('./app/shared/faq/faq.wrapper'));
const FAQListWrapper = React.lazy(() =>
  import('./app/shared/faq/faq-list.wrapper')
);
const FaqAddWrapper = React.lazy(() =>
  import('./app/shared/faq/faq-add.wrapper')
);

const DocumentListWrapper = React.lazy(() =>
  import('./app/shared/faq/document-list.wrapper')
);
const DocumentAddComponent = React.lazy(() =>
  import('./app/shared/faq/document-add-edit.component')
);

const InfoPage = React.lazy(() =>
  import('./app/views/overview/info-page.component')
);

// const PageClassNames = {
//   NO_SIDE_MARGIN: 'no-side-margin',
//   NO_TOP_MARGIN: 'no-top-margin',
//   FULLSIZE_VIEW: 'fullsize-view',
//   FULLSIZE_BLANK_VIEW: 'fullsize-blank-view',
// };

const routes = [
  // Account
  {
    path: '/reload',
    exact: true,
    type: RouteTypes.PUBLIC,
    component: () => <div></div>,
  },
  {
    path: '/faq',
    type: RouteTypes.PRIVATE,
    component: FAQWrapper,
    exact: true,
    title: 'FAQ',
    resourceKey: 'faqs',
    menuItem: 'faqs',
  },
  {
    path: '/faq/faq-list',
    type: RouteTypes.PRIVATE,
    component: FAQListWrapper,
    exact: true,
    list: 'list',
    title: 'FAQ',
    resourceKey: 'faqs',
    menuItem: 'faqs',
  },
  {
    path: '/faq/document',
    type: RouteTypes.PRIVATE,
    component: DocumentListWrapper,
    exact: true,
    list: 'list',
    title: 'FAQ',
    resourceKey: 'faqs',
    menuItem: 'faqs',
  },
  {
    path: '/faq/document/add',
    type: RouteTypes.PRIVATE,
    component: DocumentAddComponent,
    exact: true,
    title: 'FAQ',
    resourceKey: 'faqs',
    menuItem: 'faqs',
  },
  {
    path: '/faq/document/edit/:id',
    type: RouteTypes.PRIVATE,
    component: DocumentAddComponent,
    exact: true,
    title: 'FAQ',
    resourceKey: 'faqs',
    menuItem: 'faqs',
  },
  {
    path: '/faq/edit/:id',
    type: RouteTypes.PRIVATE,
    component: FaqAddWrapper,
    exact: true,
    title: 'FAQ',
    resourceKey: 'faqs',
    menuItem: 'faqs',
  },
  {
    path: '/faq/add',
    type: RouteTypes.PRIVATE,
    component: FaqAddWrapper,
    exact: true,
    title: 'FAQ',
    resourceKey: 'faqs',
    menuItem: 'faqs',
  },
  {
    path: '/account/login/:router?',
    type: RouteTypes.PUBLIC,
    component: LoginWrapper,
    exact: true,
    title: 'Login',
    resourceKey: 'login',
  },
  {
    path: '/account/forgot_password/:router?',
    type: RouteTypes.PUBLIC,
    component: ForgotPasswordWrapper,
    exact: true,
    title: 'ForgotPassword',
    resourceKey: 'forgotpassword',
  },
  {
    path: '/account/create_password/:router?',
    type: RouteTypes.PUBLIC,
    component: CreatePasswordWrapper,
    exact: true,
    title: 'CreatePassword',
    resourceKey: 'createpassword',
  },
  //dashboard
  {
    path: '/dashboard',
    type: RouteTypes.PRIVATE,
    component: DashboardListWrapper,
    exact: true,
    list: 'list',
    title: 'dashboard',
    resourceKey: 'dashboard',
    menuItem: 'dashboard',
  },
  //devices
  {
    path: '/article/devices',
    type: RouteTypes.PRIVATE,
    component: DevicesListWrapper,
    exact: true,
    list: 'list',
    title: 'horgerate - devices',
    menuItem: 'articles',
    submenuItem: 'Devices',
    resourceKey: 'ArticlesDevices',
  },
  {
    path: '/article/devices/:id',
    type: RouteTypes.PRIVATE,
    component: DevicesDetailWrapper,
    exact: true,
    title: 'Devices Details',
    menuItem: 'articles',
    submenuItem: 'Devices',
    resourceKey: 'articlesdevicesDetails',
  },
  {
    path: '/devices/edit/:id',
    type: RouteTypes.PRIVATE,
    component: DevicesEditWrapper,
    exact: true,
    title: 'Horgerate devices',
    menuItem: 'articles',
    submenuItem: 'Devices',
    resourceKey: 'articledevicesEdit',
  },
  {
    path: '/devices/new',
    type: RouteTypes.PRIVATE,
    component: DevicesNewWrapper,
    exact: true,
    title: 'Horgerate devices',
    menuItem: 'articles',
    submenuItem: 'Devices',
    resourceKey: 'articledevicesnew',
  },
  //accessories
  {
    path: '/article/accessories',
    type: RouteTypes.PRIVATE,
    component: AccessoriesListWrapper,
    exact: true,
    list: 'list',
    title: 'horgerate - accessories',
    menuItem: 'articles',
    submenuItem: 'Accessories',
    resourceKey: 'ArticlesAccessories',
  },
  {
    path: '/article/accessories/:id',
    type: RouteTypes.PRIVATE,
    component: AccessoriesDetailWrapper,
    exact: true,
    title: 'Accessories Details',
    menuItem: 'articles',
    submenuItem: 'Accessories',
    resourceKey: 'articlesAccessoriesDetails',
  },
  {
    path: '/accessories/edit/:id',
    type: RouteTypes.PRIVATE,
    component: AccessoriesEditWrapper,
    exact: true,
    title: 'Horgerate accessories',
    menuItem: 'articles',
    submenuItem: 'Accessories',
    resourceKey: 'articleaccessoriesEdit',
  },
  {
    path: '/accessories/new',
    type: RouteTypes.PRIVATE,
    component: AccessoriesNewWrapper,
    exact: true,
    title: 'Horgerate accessories',
    menuItem: 'articles',
    submenuItem: 'Accessories',
    resourceKey: 'articleaccessoriesnnew',
  },
  //Supplies
  {
    path: '/supplies',
    type: RouteTypes.PRIVATE,
    component: SuppliesListWrapper,
    exact: true,
    list: 'list',
    title: 'supplies',
    resourceKey: 'supplies',
    menuItem: 'supplies',
  },
  {
    path: '/supplies/user/:id',
    type: RouteTypes.PRIVATE,
    component: SuppliesListWrapper,
    exact: true,
    title: 'supplies',
    resourceKey: 'supplies',
    menuItem: 'supplies',
  },
  {
    path: '/supplies/documents/:id',
    type: RouteTypes.PRIVATE,
    component: PdfView,
    exact: true,
    title: 'supplies',
    resourceKey: 'supplies',
    menuItem: 'supplies',
  },
  {
    path: '/supplies/:id',
    type: RouteTypes.PRIVATE,
    component: SuppliesWrapper,
    exact: true,
    title: 'supplies',
    resourceKey: 'supplies',
    menuItem: 'supplies',
  },
  {
    path: '/patient-view',
    type: RouteTypes.PRIVATE,
    component: SuppliesPatientListWrapper,
    exact: true,
    title: 'supplies',
    resourceKey: 'patientView',
    menuItem: 'supplies',
  },
  {
    path: '/view-document/:id',
    type: RouteTypes.PRIVATE,
    component: DocumentsWrapper,
    exact: true,
    title: 'supplies',
    resourceKey: 'documentView',
    menuItem: 'supplies',
  },
  //ent-practice
  {
    path: '/contact/patient',
    type: RouteTypes.PRIVATE,
    component: PatientListWrapper,
    exact: true,
    list: 'list',
    title: 'kontakte - Patienten',
    menuItem: 'contacts',
    submenuItem: 'ContactsPatient',
    resourceKey: 'ContactsPatient',
  },
  {
    path: '/contact/patient/:id',
    type: RouteTypes.PRIVATE,
    component: PatientDetailWrapper,
    exact: true,
    list: 'list',
    title: 'kontakte - Patienten',
    menuItem: 'contacts',

    submenuItem: 'ContactsPatient',
    resourceKey: 'ContactsPatient',
  },
  {
    path: '/patient/new',
    type: RouteTypes.PRIVATE,
    component: PatientNewWrapper,
    exact: true,
    list: 'list',
    title: 'kontakte - Patienten',
    menuItem: 'contacts',

    submenuItem: 'ContactsPatient',
    resourceKey: 'ContactsPatient',
  },
  {
    path: '/contact/patient/edit/:id',
    type: RouteTypes.PRIVATE,
    menuItem: 'contacts',

    component: PatientEditWrapper,
    exact: true,
    list: 'list',
    title: 'kontakte - Patienten',
    submenuItem: 'ContactsPatient',
    resourceKey: 'ContactsPatient',
  },
  {
    path: '/contact/ent-practice',
    type: RouteTypes.PRIVATE,
    component: ENTPracticeListWrapper,
    exact: true,
    list: 'list',
    title: 'kontakte - ent-practice',
    menuItem: 'contacts',
    submenuItem: 'ENTPractice',
    resourceKey: 'ContactsENTPracticelist',
  },
  {
    path: '/contact/ent-practice/:id',
    type: RouteTypes.PRIVATE,
    component: ENTPracticeDetailWrapper,
    exact: true,
    title: 'ENTPractice Details',
    menuItem: 'contacts',
    submenuItem: 'ENTPractice',
    resourceKey: 'ContactsENTPracticeDetails',
  },
  {
    path: '/contact/ent-practice/edit/:id',
    type: RouteTypes.PRIVATE,
    component: ENTPracticeEditWrapper,
    exact: true,
    title: 'Kontakte ent-practice',
    menuItem: 'contacts',
    submenuItem: 'ENTPractice',
    resourceKey: 'contactENTPracticeEdit',
  },
  {
    path: '/ent-practice/new',
    type: RouteTypes.PRIVATE,
    component: ENTPracticeNewWrapper,
    exact: true,
    title: 'Kontakte ent-practice',
    menuItem: 'contacts',
    submenuItem: 'ENTPractice',
    resourceKey: 'contactENTPracticenew',
  },
  //hearing-aid-system-advisor
  {
    path: '/contact/hearing-aid-system-advisor',
    type: RouteTypes.PRIVATE,
    component: HearingAidSystemAdvisorListWrapper,
    exact: true,
    list: 'list',
    title: 'kontakte - HearingAidSystemAdvisor',
    menuItem: 'contacts',
    submenuItem: 'HearingAidSystemAdvisor',
    resourceKey: 'ContactsHearingAidSystemAdvisor',
  },
  {
    path: '/contact/hearing-aid-system-advisor/:id',
    type: RouteTypes.PRIVATE,
    component: HearingAidSystemAdvisorDetailWrapper,
    exact: true,
    title: 'HearingAidSystemAdvisor Details',
    menuItem: 'contacts',
    submenuItem: 'HearingAidSystemAdvisor',
    resourceKey: 'ContactsHearingAidSystemAdvisorDetails',
  },
  {
    path: '/hearing-aid-system-advisor/edit/:id',
    type: RouteTypes.PRIVATE,
    component: HearingAidSystemAdvisorEditWrapper,
    exact: true,
    title: 'Kontakte hearing-aid-system-advisor',
    menuItem: 'contacts',
    submenuItem: 'HearingAidSystemAdvisor',
    resourceKey: 'contactHearingAidSystemAdvisorEdit',
  },
  {
    path: '/hearing-aid-system-advisor/new',
    type: RouteTypes.PRIVATE,
    component: HearingAidSystemAdvisorNewWrapper,
    exact: true,
    title: 'Kontakte HearingAidSystemAdvisor',
    menuItem: 'contacts',
    submenuItem: 'HearingAidSystemAdvisor',
    resourceKey: 'contactHearingAidSystemAdvisornew',
  },
  //health-insurance
  {
    path: '/contact/health-insurance',
    type: RouteTypes.PRIVATE,
    component: HealthInsuranceListWrapper,
    exact: true,
    list: 'list',
    title: 'kontakte - health-insurance',
    menuItem: 'contacts',
    submenuItem: 'HealthInsurance',
    resourceKey: 'ContactsHealthInsurance',
  },
  {
    path: '/contact/health-insurance/:id',
    type: RouteTypes.PRIVATE,
    component: HealthInsuranceDetailWrapper,
    exact: true,
    title: 'HealthInsurance Details',
    menuItem: 'contacts',
    submenuItem: 'HealthInsurance',
    resourceKey: 'ContactsHealthInsuranceDetails',
  },
  {
    path: '/health-insurance/edit/:id',
    type: RouteTypes.PRIVATE,
    component: HealthInsuranceEditWrapper,
    exact: true,
    title: 'Kontakte HealthInsurance',
    menuItem: 'contacts',
    submenuItem: 'HealthInsurance',
    resourceKey: 'contactHealthInsuranceEdit',
  },
  {
    path: '/health-insurance/new',
    type: RouteTypes.PRIVATE,
    component: HealthInsuranceNewWrapper,
    exact: true,
    title: 'Kontakte HealthInsurance',
    menuItem: 'contacts',
    submenuItem: 'HealthInsurance',
    resourceKey: 'contactHealthInsurancenew',
  },
  //user
  {
    path: '/base-data/users',
    type: RouteTypes.PRIVATE,
    component: UsersListWrapper,
    exact: true,
    list: 'list',
    title: 'berechtigungen - users',
    menuItem: 'basedatas',
    submenuItem: 'Users',
    resourceKey: 'basedatasUsers',
  },
  //role
  {
    path: '/base-data/roles',
    type: RouteTypes.PRIVATE,
    component: RoleListWrapper,
    exact: true,
    list: 'list',
    title: 'berechtigungen - roles',
    menuItem: 'basedatas',
    submenuItem: 'Roles',
    resourceKey: 'basedatasRoles',
  },
  {
    path: '/base-data/rolepermission/:id',
    type: RouteTypes.PRIVATE,
    component: RolePermissionEditWrapper,
    exact: true,
    list: 'list',
    title: 'berechtigungen - rolepermission',
    menuItem: 'basedatas',
    submenuItem: 'Roles',
    resourceKey: 'basedatasRoles',
  },
  {
    path: '/base-data/rolepermission',
    type: RouteTypes.PRIVATE,
    component: RolePermissionNewWrapper,
    exact: true,
    list: 'list',
    title: 'rollen - rolepermission',
    menuItem: 'basedatas',
    submenuItem: 'Roles',
    resourceKey: 'basedatasRoles',
  },
  // Info
  {
    path: '/info',
    type: RouteTypes.PRIVATE,
    component: InfoPage,
    exact: true,
  },
  //Contractor Routes

  // Master Data
  {
    path: '/masterdata/site',
    type: RouteTypes.PRIVATE,
    component: SiteListWrapper,
    exact: true,
    list: 'list',
    title: 'stammdaten - Einsatzorte',
    menuItem: 'masterdata',
    submenuItem: 'site',
    resourceKey: 'masterdataSite',
  },
  {
    type: RouteTypes.ROUTE,
    component: UnmatchedRoute,
    exact: false,
  },
];

export default routes;
