import $ from 'jquery';
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import auricLogo from '../../../../assets/images/svg/auric_small_logo.svg';
import auricLogoSmall from '../../../../assets/images/svg/auric_small_logo.svg';
class MenuHeaderComponent extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    setTimeout(() => {
      $('.menuBar_container, .menuHeader_logo, .menuHeader_search')
        .off('click')
        .on('click', () => {});
    }, 0);
  }

  render() {
    const { submenuVisible, resetNewsFeedCount } = this.props;
    let { newsfeedUnreadCount } = this.props;
    let adminIndicator = null;

    if (resetNewsFeedCount) {
      newsfeedUnreadCount = 0;
    }

    return (
      <div className={`menuHeader ${submenuVisible ? 'submenu-visible' : ''}`}>
        {adminIndicator}
        <div className='menuHeader_logo'>
          <Link className={`logo`} to='/'>
            <img className='auric-small-image' src={auricLogo} />
          </Link>
          <Link className={`logo`} to='/'>
            <div className='auric-small-image-div'>
              <img className='auric-small-image' src={auricLogoSmall} />
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { resources } = state.language.lang;
  return {
    resources,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuHeaderComponent);
