import React from 'react';
import PropTypes from 'prop-types';
import MenuItemComponent from './menu-item.component';
import MenuItemWithSubmenuComponent from './menu-item-with-submenu.component';
import config from '../../../../config';

class MenuBarComponent extends React.Component {
  constructor(props) {
    super(props);
    const { activeItem, activeSubmenuItem } = props;
    this.state = {
      activeItem,
      activeSubmenuItem,
    };
  }

  componentDidMount() {}

  componentWillReceiveProps(newProps) {
    const { activeItem, activeSubmenuItem, submenuVisible } = newProps;
    const {
      activeItem: currentActiveItem,
      activeSubmenuItem: currentActiveSubmenuItem,
    } = this.state;
    const newState = Object.assign({}, this.state, { submenuVisible });

    if (activeItem !== currentActiveItem) {
      Object.assign(newState, { activeItem });

      const isMenuItemWithSubmenuActive = !!config.menuItemsData.find(
        (menuItem) => {
          return menuItem.name === activeItem && !!menuItem.submenuItems;
        }
      );

      config.menuItemsData.forEach((menuItem) => {
        if (menuItem.submenuItems) {
          if (
            menuItem.name === activeItem &&
            this.refs &&
            this.refs[`menuItemWithSubmenu-${menuItem.name}`]
          ) {
            this.refs[`menuItemWithSubmenu-${menuItem.name}`].showSubmenu(
              null,
              false,
              true
            );
          } else if (
            menuItem.name !== activeItem &&
            this.refs &&
            this.refs[`menuItemWithSubmenu-${menuItem.name}`]
          ) {
            this.refs[`menuItemWithSubmenu-${menuItem.name}`].hideSubmenu(
              isMenuItemWithSubmenuActive
            );
          }
        }
      });
    }

    if (activeSubmenuItem !== currentActiveSubmenuItem) {
      Object.assign(newState, { activeSubmenuItem });
    }

    this.setState(newState);
  }

  buildMenuItems() {
    const { activeItem, activeSubmenuItem } = this.state;
    const { submenuVisible, onSubmenuChanged, resources } = this.props;
    let items = [];
    items = [...config.menuItemsData];

    return items.map((menuItemData) => {
      const { name, submenuItems } = menuItemData;
      const active = name === activeItem;
      Object.assign(menuItemData, { active, submenuVisible });

      const currentSubItem = items.filter((item) => item.name == activeItem);

      if (submenuItems && submenuItems.length > 0) {
        return (
          <MenuItemWithSubmenuComponent
            ref={`menuItemWithSubmenu-${name}`}
            key={name}
            activeSubmenuItem={activeSubmenuItem}
            onSubmenuVisiblityChanged={onSubmenuChanged}
            {...menuItemData}
            resources={resources}
            currentSubMenu={currentSubItem}
          />
        );
      }
      return (
        <MenuItemComponent key={name} {...menuItemData} resources={resources} />
      );
    });
  }

  render() {
    const { submenuVisible } = this.props;
    const menuItems = this.buildMenuItems();
    return (
      <div className='menuBar_container'>
        <div
          className={`menuBar_content ${
            submenuVisible ? 'submenu-visible' : ''
          }`}
        >
          {menuItems}
        </div>
      </div>
    );
  }
}

MenuBarComponent.propTypes = {
  activeItem: PropTypes.string,
  activeSubmenuItem: PropTypes.string,
  submenuVisible: PropTypes.bool,
  onSubmenuChanged: PropTypes.func,
};

MenuBarComponent.defaultProps = {
  activeItem: '',
  activeSubmenuItem: '',
  submenuVisible: false,
};

export default MenuBarComponent;
