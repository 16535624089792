import { combineReducers } from 'redux';
import language from './language/index';
import supply from './supply';

const appReducer = combineReducers({
  language,
  supply,
});

const rootReducer = (state, action) => {
  if (action.type === 'invoiz/global/USER_LOGOUT') {
    state = undefined; // here we did not reset the language state property
  }
  return appReducer(state, action);
};

export default rootReducer;
