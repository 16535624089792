import { assign, isString } from 'lodash';
import Events from 'ampersand-events';
import config from '../../config';
import User from '../models/user.model';
import { request } from '../helpers/request';
import history from '../helpers/history';
import NotificationService from './notification.service';

class InvoizService {
  constructor() {
    this.user = User;
    this.releaseStage = config.releaseStage;
    this.request = request;
    this.pageHistory = [];
    this.scrollPositions = {};

    this.page = {
      showToast: (messageOrObject) => {
        this.showNotification(messageOrObject);
      },
    };

    this.router = {
      navigate: (route, forceRefresh, asyncForceRefresh) => {
        this.historyPush(route, forceRefresh, asyncForceRefresh);
      },
      redirectTo: (route, forceRefresh, asyncForceRefresh) => {
        this.historyPush(route, forceRefresh, asyncForceRefresh);
      },
      reload: (route) => {
        const pathname = window.location.pathname;

        if (route) {
          this.historyPush('/dashboard');
          setTimeout(() => {
            history.replace(route);
          }, 100);
        } else {
          this.historyPush(pathname, true, true);
        }
      },
    };

    history.listen((location, action) => {
      this.pageHistory = ((backStack) => {
        switch (action) {
          case 'POP':
            return backStack.slice(0, backStack.length - 1);
          case 'PUSH':
            return [...backStack, location];
          case 'REPLACE':
            return [...backStack.slice(0, backStack.length - 1), location];
        }
      })(this.pageHistory);
    });
  }

  saveScrollPosition = () => {
    this.scrollPositions[this.currentPath] = window.pageYOffset;
    this.currentPath = null;
  };

  restoreScrollPosition = () => {
    this.currentPath = window.location.pathname;

    if (
      this.scrollPositions[window.location.pathname] &&
      window.location.pathname ===
        this.pageHistory[this.pageHistory.length - 1]?.pathname
    ) {
      setTimeout(() => {
        window.scroll({
          left: 0,
          top: this.scrollPositions[window.location.pathname],
          behavior: 'smooth',
        });
      }, 50);
    }
  };

  historyPush(route, forceRefresh, asyncForceRefresh) {
    if (route.substr(0, 1) !== '/') {
      route = `/${route}`;
    }

    if (forceRefresh) {
      if (asyncForceRefresh) {
        setTimeout(() => {
          history.replace('/dashboard');
          setTimeout(() => {
            history.replace(route);
          }, 100);
        }, 0);
      } else {
        history.replace('/dashboard');
        setTimeout(() => {
          history.replace(route);
        }, 100);
      }
    } else {
      history.push(route);
    }
  }

  showNotification(messageOrObject) {
    if (isString(messageOrObject)) {
      NotificationService.show({
        type: 'success',
        message: messageOrObject,
      });
    } else {
      NotificationService.show({
        type: messageOrObject.type === 'error' ? 'error' : 'success',
        message: messageOrObject.message || '',
        wrapperClass: messageOrObject.wrapperClass,
      });
    }
  }
}

assign(InvoizService.prototype, {
  off: Events.off,
  on: Events.on,
  trigger: Events.trigger,
});

export default new InvoizService();
