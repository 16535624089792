import _ from 'lodash';
import React from 'react';
import MenuHeaderComponent from './menu-header.component';
import MenuBarComponent from './menu-bar.component';
import MenuFooterComponent from './menu-footer.component';
import { connect } from 'react-redux';

class MenuComponent extends React.Component {
  constructor(props) {
    super(props);

    const { activeItem, activeSubmenuItem } = props;

    this.isNewsfeedToggling = false;

    this.state = {
      activeItem,
      activeSubmenuItem,
      hideMenu: false,
      submenuVisible: false,
      isNewsfeedVisible: false,
    };
  }

  componentWillReceiveProps(newProps) {
    const { activeItem, activeSubmenuItem } = newProps;
    const {
      activeItem: currentActiveItem,
      activeSubmenuItem: currentActiveSubmenuItem,
    } = this.state;

    const newState = Object.assign({}, this.state);

    if (activeItem && activeItem !== currentActiveItem) {
      Object.assign(newState, { activeItem });
    }

    if (activeSubmenuItem !== currentActiveSubmenuItem) {
      Object.assign(newState, { activeSubmenuItem });
    }

    const user_Data =
      // userMail + "=" +
      activeItem + ',' + activeSubmenuItem;

    // localStorage.setItem("activeTab", user_Data);

    localStorage.setItem('activeItem', activeItem);

    localStorage.setItem('activeSubmenuItem', activeSubmenuItem);

    this.setState(newState);
  }

  onLogout(logoutCallback) {
    this.setState({ hideMenu: true }, () => {
      logoutCallback();
    });
  }

  onSubmenuChanged = (submenuVisible) => {
    setTimeout(() => {
      this.setState({ submenuVisible });
    }, 0);
  };

  render() {
    const { activeItem, activeSubmenuItem, hideMenu, submenuVisible } =
      this.state;
    const { resources } = this.props;

    const content = hideMenu ? null : (
      <nav className={submenuVisible ? 'menu' : 'menu menu-without-submenu'}>
        <MenuHeaderComponent submenuVisible={submenuVisible} />
        <MenuBarComponent
          activeItem={activeItem}
          activeSubmenuItem={activeSubmenuItem}
          submenuVisible={submenuVisible}
          onSubmenuChanged={this.onSubmenuChanged}
          resources={resources}
        />
        <MenuFooterComponent
          submenuVisible={submenuVisible}
          onLogout={this.onLogout.bind(this)}
        />
      </nav>
    );

    return content;
  }
}
const mapStateToProps = (state) => {
  const { resources } = state.language.lang;
  return {
    resources,
  };
};

export default connect(mapStateToProps)(MenuComponent);
