import React from 'react';
import ReactDOM from 'react-dom/client';

import ModalBaseComponent from '../shared/modals/modal-base.component';
import $ from 'jquery';

// Instructions for opening multiple modals simultaneously
// Step 1: (Very Important)
// Create a new modalService instance and provide some unique id.
// Make sure you import ModalService class not the default service instance
// import { ModalService } from '../../services/modal.service';
// detailModal = new ModalService('unique-id-for-modal')
//
// Step 2:
// Now use this instance for opening and close the modal
//  ex:
// detailModal.open(<Component />,{options})
// detailModal.close()
//
// Step 3: (Very Important)
// Destroy the instance once it is no longer required
// (ex: when you navigate to some other screen or page where this instance is no longer needed or not accessible )
// detailModal.destroy()

let openModalCount = 0;

class ModalService {
  constructor(id) {
    const modalElement = document.createElement('div');
    const elementId = id || 'modal-component-wrapper';
    modalElement.id = elementId;
    modalElement.style.setProperty('display', 'none');
    document.body.appendChild(modalElement);

    this.id = elementId;

    setTimeout(() => {
      ReactDOM.createRoot(document.getElementById(elementId)).render(
        React.createElement(ModalBaseComponent, {
          ref: (r) => r && (this.modalBase = r),
        })
      );
    });
  }

  open(content, options) {
    $('body').addClass('has-modal');
    $(`#${this.id}`)?.css('display', 'block');
    openModalCount++;

    if (options.isFullscreen) {
      $('body').addClass('fullscreen-modal');
    }

    this.modalBase.open(content, options, this);
  }

  close(isFromCancel) {
    this.modalBase.isOpen?.() && openModalCount--;

    this.modalBase.close(isFromCancel);

    if (openModalCount <= 0) {
      $('body').removeClass('has-modal');
    }
    $(`#${this.id}`)?.css('display', 'none');
    setTimeout(() => {
      $('body').removeClass('fullscreen-modal');
    }, 300);
  }

  destroy() {
    this.id !== 'modal-component-wrapper' &&
      document.body.removeChild(document.querySelector(`#${this.id}`));
  }
}

export { ModalService };

export default new ModalService();
