// ********************************
// remove this file after migrating content to lang_de.json
// ********************************

export default {
  // autoNumberPlaceholder: 'Autom. Vergabe',
  // customerSearch: 'Hörgeräte',
  // articleSearch: 'Hörgeräte',
  // accessoriesSearch: 'Zubehör durchsuchen',
  // siteSearch: 'Einsatzorte',
  // ticketSearch: 'Serviceprotokolle',
  // attentionMessage:
  //   'ACHTUNG: dieser Vorgang kann nicht rückgängig gemacht werden!',
  customersDeleteNotAllowedMessage: 'Not allow',
  defaultErrorMessage: 'Entschuldigung, es ist ein Fehler aufgetreten!',
  // userSearch: 'Nutzer',
  // roleSearch: 'Rollen',
  // hsbSearch: 'HSB durchsuchen',
  // entSearch: 'HNO Praxen durchsuchen',
  // ServerError: 'Server nicht verfügbar!',
  // ClientError: 'Etwas ist schief gelaufen!',
  // NotFound: 'API nicht gefunden!',
  // InvalidToken: 'Autorisierungs-Token ungültig!',
  // PatientAlreadyExist: 'Patient existiert bereits!',
};
