import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { userLoggedOut } from '../../../redux/ducks/global';

class MenuFooterComponent extends React.Component {
  onLogoutClick() {
    const { onLogout } = this.props;

    onLogout(() => {
      localStorage.removeItem('user');

      window.location.href = '/';
    });
  }

  render() {
    const { submenuVisible } = this.props;
    const ImpClass = `menuItem ${submenuVisible ? 'menuItem-notFocused' : ''}`;

    return (
      <div className='menuFooter'>
        <Link
          to={'/info'}
          style={{
            color: '#bac6d1 !important',
            height: '29px',
            minHeight: '29px',
          }}
          className={ImpClass}
        >
          <span className='collapsed-title' style={{ top: '5px' }}>
            Info
          </span>
        </Link>

        <p className='audito-version-dis'>
          <span
            className='collapsed-title'
            style={{ top: '5px', fontSize: '14px', width: '100%' }}
          >
            Version : 1.0
          </span>
        </p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { resources } = state.language.lang;
  return {
    resources,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    userLoggedOut: () => {
      dispatch(userLoggedOut());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuFooterComponent);
