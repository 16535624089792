import PropTypes from 'prop-types';

const SubmenuBarComponent = ({
  title,
  name,
  visible,
  hasImprintAndPrivacy,
  children,
  currentSubMenu,
}) => {
  const visibleClass = visible ? 'submenu-visible' : 'u_hidden';
  // const visibleClass = children.length > 0 ? (window.innerWidth > 1299 ? 'submenu-visible' : 'u_hidden') : 'u_hidden';
  const className = `submenu submenu-${name} ${visibleClass}`;

  const imprint = '';

  return (
    <div className={className}>
      <h5 className='submenuTitle'>
        {currentSubMenu.length > 0
          ? currentSubMenu[0].title.toUpperCase()
          : title.toUpperCase()}
      </h5>

      <ul className='submenuList'>{children}</ul>
      {hasImprintAndPrivacy ? imprint : null}
    </div>
  );
};

SubmenuBarComponent.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
};

SubmenuBarComponent.defaultProps = {
  title: '',
  visible: false,
};

export default SubmenuBarComponent;
