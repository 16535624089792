import _ from 'lodash';
import qs from 'qs';
import invoiz from '../services/invoiz.service';
import config from '../../config';

export const request = (endpoint, options) => {
  options = options || {};

  const fetchOptions = {
    method: options.method || 'GET',
    headers: options.headers || { 'Content-Type': 'application/json' },
  };

  if (
    options.auth === true &&
    (options.authCustomBearerToken || (invoiz.user && invoiz.user.token))
  ) {
    if (options.authCustomBearerToken) {
      fetchOptions.headers.authtoken = options.authCustomBearerToken;
      fetchOptions.headers['api-version'] = '1';

      if (options.requestId) {
        fetchOptions.headers['logLevel'] = 'Debug';
        fetchOptions.headers['requestId'] = options.requestId;
      }
    } else {
      fetchOptions.headers.authorization = `Bearer ${invoiz.user.token}`;
    }
  }

  if (
    options.customHeaders &&
    options.customHeaders &&
    Object.keys(options.customHeaders).length > 0
  ) {
    Object.keys(options.customHeaders).forEach((key) => {
      fetchOptions.headers[key] = options.customHeaders[key];
    });
  }

  if (fetchOptions.method === 'GET' && options.data) {
    const forXDataOptimizedParams = _.reduce(
      options.data,
      (result, value, key) => {
        result[key] = _.isString(value) ? `'${value}'` : value;
        return result;
      },
      {}
    );

    endpoint = `${fetchOptions.url}?${qs.stringify(forXDataOptimizedParams)}`;
  } else if (fetchOptions.method !== 'GET' && options.data) {
    fetchOptions.body = JSON.stringify(options.data);
  }

  if (fetchOptions.method !== 'GET' && options.responseType) {
    fetchOptions.responseType = options.responseType;
  }

  return new Promise((resolve, reject) => {
    fetch(endpoint, fetchOptions)
      .then((res) => {
        if (!res.ok) {
          if (
            res.status === 401 &&
            res.url !== config.account.endpoints.login
          ) {
            invoiz.user.logout();
          } else {
            reject(
              res.text().then((res) => {
                return JSON.parse(res);
              })
            );
          }
          throw Error(res.statusText);
        }
        return res.text();
      })
      .then((text) => {
        try {
          if (typeof text === 'string') {
            const data = JSON.parse(text);
            resolve({ body: data });
          }
          if (typeof text === 'object') {
            resolve({ body: text });
          } else {
            resolve(true);
          }
        } catch (err) {
          resolve(true);
        }
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};
