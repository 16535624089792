import $ from 'jquery';
import React from 'react';
import PropTypes from 'prop-types';

import invoiz from '../../../services/invoiz.service';
import SubMenuBarComponent from './submenu-bar.component';
import SubMenuItemComponent from './submenu-item.component';

const buildSubmenuComponents = (submenuItems, activeSubmenuItem, resources) => {
  return submenuItems.map((submenuItemData) => {
    const { name } = submenuItemData;
    let active;

    if (name === activeSubmenuItem) {
      active = true;
    }

    return (
      <SubMenuItemComponent
        key={name}
        active={active}
        {...submenuItemData}
        resources={resources}
      />
    );
  });
};

class MenuItemWithSubmenuComponent extends React.Component {
  constructor(props) {
    super(props);

    const { activeSubmenuItem } = this.props;

    this.state = {
      submenuVisible: false,
      activeSubmenuItem,
      isCollapsedState: false,
      canViewTextBlocks: null,
      canViewDunning: null,
      canViewTimesheet: null,
    };

    this.windowResizeTimeout = null;
    this.onWindowResize = this.onWindowResize.bind(this);

    $(window).off('resize', this.onWindowResize);
    $(window).on('resize', this.onWindowResize);
  }

  componentDidMount() {
    this.onWindowResize();
    invoiz.on('triggerSubmenuHide', this.hideSubmenu, this);
    invoiz.on('triggerSubmenuSwitch', this.switchSubmenu, this);
  }

  componentWillReceiveProps(newProps) {
    const { activeSubmenuItem } = newProps;
    const { activeSubmenuItem: currentActiveSubmenuItem } = this.state;

    if (activeSubmenuItem !== currentActiveSubmenuItem) {
      this.setState({ activeSubmenuItem });
    }
  }

  componentWillUnmount() {
    invoiz.off('triggerSubmenuHide', this.hideSubmenu, this);
    invoiz.off('triggerSubmenuSwitch', this.switchSubmenu, this);
    $(window).off('resize', this.onWindowResize);
  }

  hideSubmenu(noChangeTrigger) {
    this.setState({ submenuVisible: false }, () => {
      const { onSubmenuVisiblityChanged } = this.props;

      if (!noChangeTrigger) {
        onSubmenuVisiblityChanged(false);
      }
    });
  }

  isSubmenuClick(evt) {
    return (
      evt &&
      evt.nativeEvent.target &&
      $(evt.nativeEvent.target).closest('.submenu').length > 0
    );
  }

  showSubmenu(evt, isClick, checkCollapsedState) {
    const { isCollapsedState } = this.state;
    const isSubmenuClick = isClick && this.isSubmenuClick(evt);
    let showSubmenu = true;

    if (checkCollapsedState) {
      showSubmenu = !isCollapsedState;
    }
    if (!isSubmenuClick && showSubmenu) {
      invoiz.trigger('triggerSubmenuSwitch');

      this.setState({ submenuVisible: true }, () => {
        const { onSubmenuVisiblityChanged } = this.props;
        onSubmenuVisiblityChanged(true);

        if (isClick) {
          this.navigateToFirstSubmenuItem();
        }
      });
    }
  }

  navigateToFirstSubmenuItem(evt) {
    const { submenuItems } = this.props;
    const isSubmenuClick = this.isSubmenuClick(evt);
    if (submenuItems && submenuItems[0].url && !isSubmenuClick) {
      invoiz.router.navigate(submenuItems[0].url);
    }
  }

  switchSubmenu() {
    this.setState({ submenuVisible: false });
  }

  onWindowResize() {
    clearTimeout(this.windowResizeTimeout);

    this.windowResizeTimeout = setTimeout(() => {
      if (this.refs.subMenuBarNormal || this.refs.subMenuBarCollapsed) {
        this.setState({
          isCollapsedState: !window.matchMedia('(min-width:1299px)').matches,
        });

        if (!window.matchMedia('(min-width:1299px)').matches) {
          this.hideSubmenu();
        } else {
          this.showSubmenu();
        }
      }
    }, 100);
  }

  render() {
    const { submenuVisible, activeSubmenuItem, isCollapsedState } = this.state;
    const {
      title,
      name,
      submenuItems,
      icon,
      active,
      hasImprintAndPrivacy,
      resourceKey,
      resources,
      currentSubMenu,
    } = this.props;

    // const submenuItemComponents = buildSubmenuComponents(
    //   submenuItems,
    //   activeSubmenuItem,
    //   resources
    // );

    const submenuItemComponents = buildSubmenuComponents(
      currentSubMenu.length > 0 ? currentSubMenu[0].submenuItems : [],
      activeSubmenuItem,
      resources
    );
    const iconClass = `icon icon-${icon}`;
    const activeClass = active ? 'menuItem-active' : '';
    const className = `menuItem menuItem-hasSubmenu ${iconClass} ${activeClass}`;

    return isCollapsedState ? (
      <div
        ref='subMenuBarCollapsed'
        onMouseEnter={() => this.showSubmenu()}
        onMouseLeave={() => this.hideSubmenu()}
        onClick={(e) => this.navigateToFirstSubmenuItem(e)}
        className={className}
        data-href={submenuItems[0].url}
        data-qs-id={`global-menu-item-${name}`}
      >
        {/* {title} */}
        {/* {resources.menuItems[resourceKey]} */}
        <span className='collapsed-title'>{title}</span>
        <SubMenuBarComponent
          visible={submenuVisible}
          title={title}
          name={name}
          hasImprintAndPrivacy={hasImprintAndPrivacy}
          resourceKey={resourceKey}
          resources={resources}
          currentSubMenu={currentSubMenu}
        >
          {submenuItemComponents}
        </SubMenuBarComponent>
      </div>
    ) : (
      <div
        ref='subMenuBarNormal'
        onClick={(e) => this.showSubmenu(e, true)}
        className={className}
        data-href={submenuItems[0].url}
        data-qs-id={`global-menu-item-${name}`}
      >
        {/* {title} */}
        {/* {resources.menuItems[resourceKey]} */}
        <span className='collapsed-title'>{title}</span>
        <SubMenuBarComponent
          visible={submenuVisible}
          title={title}
          name={name}
          hasImprintAndPrivacy={hasImprintAndPrivacy}
          resourceKey={resourceKey}
          resources={resources}
          currentSubMenu={currentSubMenu}
        >
          {submenuItemComponents}
        </SubMenuBarComponent>
      </div>
    );
  }
}

MenuItemWithSubmenuComponent.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  icon: PropTypes.string,
  active: PropTypes.bool,
  submenuItems: PropTypes.array,
  activeSubmenuItem: PropTypes.string,
  onSubmenuVisiblityChanged: PropTypes.func,
  resourceKey: PropTypes.string,
  currentSubMenu: PropTypes.array,
};

MenuItemWithSubmenuComponent.defaultProps = {
  title: '',
  url: '',
  icon: '',
  active: false,
  submenuItems: [],
  activeSubmenuItem: '',
  resourceKey: '',
  currentSubMenu: [],
};

export default MenuItemWithSubmenuComponent;
