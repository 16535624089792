import WebStorageService from '../services/webstorage.service';
import WebStorageKey from '../enums/web-storage-key.enum';
class User {
  constructor() {
    const data = WebStorageService.getItem(WebStorageKey.USER, true) || {};
    this.loggedIn = data.loggedIn || false;
    this.token = data.token;
    this.contactType = data.contactType;
    this.contactId = data.contactId || '';
    this.hnoPracticeName = data.hnoPracticeName;
    this.role = data.role;
    this.email = data.email;
    this.name = data.name;
    this.permission = data.permission;
  }

  login(response) {
    const {
      body: {
        data: { token },
      },
    } = response;

    this.token = token;
    this.tokenCheckedAt = new Date().toISOString();
    return new Promise((resolve, reject) => {
      const returnUrl = WebStorageService.getItem(
        WebStorageKey.RETURN_AFTER_LOGIN,
        true
      );

      if (returnUrl) {
        if (returnUrl.indexOf('pendo') === -1) {
          resolve(returnUrl);
        } else {
          window.location.href = window.location.origin + returnUrl;
        }
      } else {
        resolve('/dashboard');
      }

      WebStorageService.removeItem(WebStorageKey.RETURN_AFTER_LOGIN, true);
    });
  }
  setInfo(response) {
    const {
      body: {
        data: {
          name,
          role,
          permission,
          contactType,
          hnoPracticeName,
          contactId,
        },
      },
    } = response;

    this.role = role;
    this.permission = permission;
    this.name = name;
    this.loggedIn = true;
    this.contactType = contactType;
    this.hnoPracticeName = hnoPracticeName;
    this.contactId = contactId || '';
    return new Promise((resolve, reject) => {
      const returnUrl = WebStorageService.getItem(
        WebStorageKey.RETURN_AFTER_LOGIN,
        true
      );

      if (returnUrl) {
        if (returnUrl.indexOf('pendo') === -1) {
          resolve(returnUrl);
        } else {
          window.location.href = window.location.origin + returnUrl;
        }
      } else {
        resolve('/dashboard');
      }

      WebStorageService.removeItem(WebStorageKey.RETURN_AFTER_LOGIN, true);
    });
  }
  logout() {
    // WebStorageService.clear();

    localStorage.removeItem('user');

    window.location.href = '/';
  }
}
const handler = {
  get(target, name) {
    return target[name];
  },

  set(target, name, value) {
    if (target.hasOwnProperty(name)) {
      target[name] = value;
      WebStorageService.setItem(
        WebStorageKey.USER,
        JSON.stringify(target),
        true
      );
    }

    return true;
  },
};

const user = new User();

if (!window.Proxy) {
  window.Proxy = function () {};
}

export default new Proxy(user, handler);
