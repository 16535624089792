import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import AppComponent from './app/app';
import { LicenseManager } from '@ag-grid-enterprise/core';
import {
  SetFilter,
  TabbedLayout,
  ModuleRegistry,
  Component,
  AgInputTextField,
  GridOptionsWrapper,
  ProvidedFilter,
  DragAndDropService,
  AllCommunityModules,
} from '@ag-grid-enterprise/all-modules';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { GlobalWorkerOptions } from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import './styles/app.scss';

GlobalWorkerOptions.workerSrc = pdfjsWorker;
ModuleRegistry.registerModules([AllCommunityModules, ServerSideRowModelModule]);
//require('./assets/fonts');

// const injectTapEventPlugin = require('react-tap-event-plugin');
// injectTapEventPlugin();

LicenseManager.setLicenseKey(
  `CompanyName=schlau DIGITAL GmbH,LicensedApplication=Administration Backend,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-019253,ExpiryDate=20_September_2022_[v2]_MTY2MzYyODQwMDAwMA==3be7716f43d31b760c8147891b3f0fe3`
);

window.$ = window.jQuery = require('jquery');
require('jquery-nearest');
//require('pdfjs-dist/webpack');

const applyAgGridOverrides = () => {
  //
  // AG.COMMUNITY: Override DragAndDropService.enterDragTargetIfExists() to suppress pinned icon in ag-dnd-ghost
  //
  const DragAndDropServiceEnterDragTargetIfExists =
    DragAndDropService.prototype.enterDragTargetIfExists;

  DragAndDropService.prototype.enterDragTargetIfExists = function (
    dropTarget,
    mouseEvent,
    hDirection,
    vDirection,
    fromNudge
  ) {
    DragAndDropServiceEnterDragTargetIfExists.call(
      this,
      dropTarget,
      mouseEvent,
      hDirection,
      vDirection,
      fromNudge
    );

    if (
      dropTarget &&
      dropTarget.getIconName &&
      dropTarget.getIconName() === 'pinned'
    ) {
      this.setGhostIcon(hDirection === 1 ? 'right' : 'left');
    }
  };

  //
  // AG.COMMUNITY: Override GridOptionsWrapper.checkProperties() to suppress warnings for custom grid properties
  //
  const GridOptionsWrapperCheckProperties =
    GridOptionsWrapper.prototype.checkProperties;

  GridOptionsWrapper.prototype.checkProperties = function (
    userProperties,
    validPropertiesAndExceptions,
    validProperties,
    containerName,
    docsUrl
  ) {
    const nonDefaultGridProperties = ['customProps', 'onCellContextMenuClosed'];

    GridOptionsWrapperCheckProperties.call(
      this,
      userProperties,
      validPropertiesAndExceptions.concat(nonDefaultGridProperties),
      validProperties.concat(nonDefaultGridProperties),
      containerName,
      docsUrl
    );
  };

  //
  // AG.COMMUNITY: Override AgInputTextField.addInputListeners() to add search icon
  //
  const AgInputTextFieldAddInputListeners =
    AgInputTextField.prototype.addInputListeners;

  AgInputTextField.prototype.addInputListeners = function () {
    AgInputTextFieldAddInputListeners.call(this);
    $('<div/>', { class: 'icon icon-search' }).appendTo($(this.eWrapper));
  };

  //
  // AG.COMMUNITY: Override Component.destroy() to add event when context menu is closed
  //
  const ComponentDestroy = Component.prototype.destroy;

  Component.prototype.destroy = function () {
    ComponentDestroy.call(this);

    if (this.constructor.name === 'ContextMenu') {
      if (
        this.menuList &&
        this.menuList.gridOptionsWrapper &&
        this.menuList.gridOptionsWrapper.gridOptions &&
        this.menuList.gridOptionsWrapper.gridOptions.onCellContextMenuClosed
      ) {
        this.menuList.gridOptionsWrapper.gridOptions.onCellContextMenuClosed();
      }
    }
  };

  //
  // AG.COMMUNITY: Override ProvidedFilter.onUiChanged() to apply filters directly instead of "Apply" button
  //
  const onUiChanged = ProvidedFilter.prototype.onUiChanged;

  ProvidedFilter.prototype.onUiChanged = function (afterFloatingFilter) {
    onUiChanged.call(this, true);
  };

  //
  // AG.ENTERPRISE: Override SetFilter.createSetListItem() to allow filter items HTML values
  //
  const SetFilterCreateSetListItem = SetFilter.prototype.createSetListItem;

  SetFilter.prototype.createSetListItem = function (value) {
    const setListItem = SetFilterCreateSetListItem.call(this, value);

    if (
      setListItem.params.colDef &&
      setListItem.params.colDef.customProps &&
      setListItem.params.colDef.customProps.filterListItemValueRenderer
    ) {
      setListItem.params.colDef.customProps.filterListItemValueRenderer(
        setListItem.value,
        setListItem.eGui,
        setListItem
      );
    }

    return setListItem;
  };

  //
  // AG.ENTERPRISE: Override getTemplate() to add popup arrow
  //
  TabbedLayout.getTemplate = function (cssClass) {
    return (
      '<div class="ag-tabs ' +
      cssClass +
      '">' +
      '<div class="ag-tabs-arrow"></div>' +
      '<div ref="eHeader" class="ag-tabs-header ' +
      (cssClass ? cssClass + '-header' : '') +
      '"></div>' +
      '<div ref="eBody" class="ag-tabs-body ' +
      (cssClass ? cssClass + '-body' : '') +
      '"></div>' +
      '</div>'
    );
  };
};
applyAgGridOverrides();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AppComponent />
  </BrowserRouter>
);
