import config from '../../config';

export const customerTypes = {
  COMPANY: 'company',
  PERSON: 'person',
};

export const columnViewStates = {
  COLUMN_VIEW_STATE_DISPLAY: 'display',
  COLUMN_VIEW_STATE_FORM: 'form',
};

// move this constant json into lang resource file
export const errorCodesWithMessages = [
  { type: 'INCORRECT', message: '%s ist falsch' },
  { type: 'INVALID', message: '%s ist nicht gültig' },
  { type: 'NO_INTEGER', message: 'Bitte gib eine valide Zahl ein' },
  { type: 'NO_NUMBER', message: 'Bitte gib eine valide Gleitkommazahl ein' },
  { type: 'NO_DATE', message: 'Bitte gib ein valides Datum ein' },
  {
    type: 'NO_DATETIME',
    message: 'Bitte gib ein valides Datum mit Uhrzeit ein',
  },
  { type: 'NO_STRING', message: 'Bitte gib einen validen Text ein' },
  { type: 'NOT_ALLOWED', message: '%s ist nicht gültig' },
  { type: 'NOT_FOUND', message: '%s existiert nicht' },
  { type: 'NOT_POSITIVE', message: 'Der eingegebene Wert muss positiv sein' },
  { type: 'REQUIRED', message: 'Dies ist ein Pflichtfeld' },
  { type: 'TOO_SMALL', message: 'Der eingegebene Wert ist zu klein' },
  { type: 'TOO_LARGE', message: 'Der eingegebene Wert ist zu groß' },
  { type: 'EXISTS', message: '%s existiert bereits' },
];

export const errorCodes = {
  REQUIRED: 'REQUIRED',
  NOT_EMPTY: 'NOT_EMPTY',
  INCORRECT: 'INCORRECT',
  INVALID: 'INVALID',
  NO_INTEGER: 'NO_INTEGER',
  NO_NUMBER: 'NO_NUMBER',
  NO_DATE: 'NO_DATE',
  NO_DATETIME: 'NO_DATETIME',
  NO_STRING: 'NO_STRING',
  NOT_ALLOWED: 'NOT_ALLOWED',
  NOT_FOUND: 'NOT_FOUND',
  NOT_POSITIVE: 'NOT_POSITIVE',
  NOT_CONFIRMED: 'NOT_CONFIRMED',
  TOO_SMALL: 'TOO_SMALL',
  TOO_LARGE: 'TOO_LARGE',
  TOO_MANY: 'TOO_MANY',
  IS_CONFIRMED: 'IS_CONFIRMED',
  EXISTS: 'EXISTS',
};

export const columnNames = {
  DESCRIPTION: 'description',
  NUMBER: 'number',
  AMOUNT: 'amount',
  VAT: 'vat',
  PRICE: 'price',
  DISCOUNT: 'discount',
  TOTAL: 'total',
};

// currently not in use
export const columnPlaceholders = {
  DESCRIPTION: 'Bezeichnung',
  NUMBER: 'Artikel-Nr.',
  AMOUNT: 'Menge',
  VAT: 'MwSt.',
  PRICE: 'Preis',
  DISCOUNT: 'Rabatt',
  TOTAL: 'Gesamt',
};

export const RouteTypes = {
  PRIVATE: 'private',
  PUBLIC: 'public',
  ROUTE: 'route',
};
export const htmlInputEmptyStates = {
  DEFAULT_HTML_EMPTY_STATE: '<p><br></p>',
  HTML_LIST_EMPTY_STATE: '<ul><li><br></li></ul>',
};
export const notesStates = {
  NOTES_DISPLAY_STATE: 'display',
  NOTES_FORM_STATE: 'form',
};
export const RegExPatterns = {
  HTTP_HTTPS: /^(http|https):\/\//,
};
export const ListAdvancedDefaultSettings = {
  CellInlineActionType: {
    MAIL: 'mail',
    MAPS: 'maps',
    PHONE: 'phone',
    WEBSITE: 'website',
    VIEW: 'view',
  },
  COLUMN_MIN_WIDTH: 60,
  CUSTOMER_TYPE_CONTACTPERSON: 'contactPerson',
  DATE_FILTER_PARAMS_OPTIONS: [
    'equals',
    {
      displayKey: 'greaterThanDate',
      displayName: 'Später als >',
      test: (filterValue, cellValue) => {
        return (
          dateCompare(filterValue, cellValue, config.dateFormat.client) > 0
        );
      },
    },
    {
      displayKey: 'lessThanDate',
      displayName: 'Früher als <',
      test: (filterValue, cellValue) => {
        return (
          dateCompare(filterValue, cellValue, config.dateFormat.client) < 0
        );
      },
    },
    'notEqual',
    'inRange',
  ],
  EXCEL_STYLE_IDS: {
    // Also used as styles in styles/vendor/_ag-grid-theme-invoiz.scss
    Currency: 'ExcelCurrency',
    Percentage: 'ExcelPercentage',
    String: 'ExcelString',
  },
  TEXT_FILTER_OPTIONS: {
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      suppressAndOrCondition: true,
    },
    customProps: {
      isFilterBodyOnly: true,
    },
  },
  TEXT_FILTER_OPTIONS_PHONE: {
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      suppressAndOrCondition: true,
      textCustomComparator: function (_, value, filterText) {
        const filterTextLowerCase = filterText
          .toLowerCase()
          .replace(/[()/+-\s]/gi, '');

        const valueLowerCase = value
          .toString()
          .toLowerCase()
          .replace(/[()/+-\s]/gi, '');

        return (
          valueLowerCase && valueLowerCase.indexOf(filterTextLowerCase) >= 0
        );
      },
    },
    customProps: {
      isFilterBodyOnly: true,
    },
  },
};
