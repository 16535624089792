import lang_de from '../../../../lang/lang_de.json';
/*
 * Actions
 */
const FETCHING_LANGUAGE_DETAIL = 'FETCHING_LANGUAGE_DETAIL';
const ERROR_FETCHING_LANGUAGE_DETAIL = 'ERROR_FETCHING_LANGUAGE_DETAIL';

/*
 * Reducer
 */
const initialState = {
  resources: null,
  language: '',
  isLoading: true,
  errorOccurred: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCHING_LANGUAGE_DETAIL:
      return {
        resources: action.payload.data,
        language: action.payload.language,
        isLoading: false,
        errorOccurred: false,
      };
    case ERROR_FETCHING_LANGUAGE_DETAIL: {
      return {
        ...state,
        type: action.langType,
        isLoading: false,
        errorOccurred: true,
      };
    }
    default:
      return state;
  }
}

/*
 * Action Creators
 */
const fetchingLanguageDetail = (payload) => {
  return {
    type: FETCHING_LANGUAGE_DETAIL,
    payload,
  };
};

const errorFetchingLanguageDetail = (language) => {
  return {
    type: ERROR_FETCHING_LANGUAGE_DETAIL,
    language,
  };
};

export const fetchLanguageFile = (language) => (dispatch) => {
  //const date = moment().unix();
  try {
    //if (lang_de)
    const payload = {
      data: lang_de,
      language,
    };
    dispatch({
      type: FETCHING_LANGUAGE_DETAIL,
      payload,
    });
    //return payload;
  } catch (error) {
    //   dispatch(errorFetchingLanguageDetail(language));
  }
};
