import { ModalService } from '../../../services/modal.service';

const initialModalData = {
  deviceVariants: [], // All List of devices for first modal
  accessoriesVariants: [], // All list of accesories for second modal
  filters: null, // Filters applied in first modal
  priceFilter: null,
  selectedDevices: [], // Devices Selected
  accForDevice: {}, // Particular device selected to show accessories available only for that
  activeStep: 'devices', // devices | accessories | finalStep
};
const initialState = {
  resources: {},
  supplyId: '',
  supply: {},
  supplyType: '',
  isInsurancePKV: false,
  colors: [],
  deviceWhos: [],
  deviceBatteryTypes: [],
  deviceManufacturers: [],
  deviceTechnicalLevels: [],
  deviceDesignTypes: [],
  deviceBluetoothTypes: [],
  ...initialModalData,
};
let variantDetailModal;
let imagePreviewModal;

const INIT_STATE = 'INIT_STATE';
const RESET_STATE = 'RESET_STATE'; // When modal is closed or data is saved...
const ADD_FILTERS = 'ADD_FILTERS';
const ADD_PRICE_FILTER = 'ADD_PRICE_FILTER';
const ADD_DEVICES_LIST = 'ADD_DEVICES_LIST';
const ADD_ACCESSORIES_LIST = 'ADD_ACCESSORIES_LIST';
const UPDATE_DEVICE_SELECTION = 'UPDATE_DEVICE_SELECTION';
const SELECT_DEVICE_FOR_ACC = 'SELECT_DEVICE_FOR_ACC';
const UPDATE_SELECTED_STEP = 'UPDATE_SELECTED_STEP';
const UPDATE_FILTER_OPTIONS = 'UPDATE_FILTER_OPTIONS';

function reducer(state = initialState, action) {
  switch (action.type) {
    case INIT_STATE:
      return { ...state, ...action.payload };

    case UPDATE_FILTER_OPTIONS:
      return { ...state, ...action.payload };

    case ADD_FILTERS:
      return { ...state, filters: action.payload };

    case ADD_PRICE_FILTER:
      return { ...state, priceFilter: action.payload };

    case ADD_DEVICES_LIST:
      return { ...state, deviceVariants: action.payload };

    case ADD_ACCESSORIES_LIST:
      return { ...state, accessoriesVariants: action.payload };

    case UPDATE_DEVICE_SELECTION:
      return { ...state, selectedDevices: action.payload };

    case SELECT_DEVICE_FOR_ACC:
      return { ...state, accForDevice: action.payload };

    case UPDATE_SELECTED_STEP:
      return { ...state, ...action.payload };

    case RESET_STATE:
      return { ...state, ...initialModalData, selectedDevices: [] };

    default:
      return state;
  }
}

export default reducer;

export const initArticleModalState = (initPayload) => (dispatch, getState) => {
  initPayload.supplyId = initPayload?.supply?.id;
  initPayload.supplyType = initPayload?.supply?.typeOfSupply;
  initPayload.resources = getState()?.language.lang?.resources;
  initPayload.isInsurancePKV =
    initPayload?.supply?.patient?.insuranceName === 'PKV';

  dispatch({ type: INIT_STATE, payload: initPayload });
};

export const updateFilterOptions = (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_FILTER_OPTIONS,
    payload: {
      deviceBatteryTypes: payload.batteryTypes,
      deviceBluetoothTypes: payload.bluetoothTypes,
      colors: payload.colors.map((i) => ({ name: i.colorName })),
      deviceDesignTypes: payload.designTypes,
      deviceManufacturers: payload.manufacturers,
      deviceTechnicalLevels: payload.technicalLevels,
      deviceWhos: payload.whos,
    },
  });
};

export const addDevicesList = (devices) => (dispatch) => {
  dispatch({ type: ADD_DEVICES_LIST, payload: devices });
};

export const addAccessoriesList = (accesories) => (dispatch, getState) => {
  dispatch({ type: ADD_ACCESSORIES_LIST, payload: accesories });

  const devices = getState()?.supply?.supplyArticle?.selectedDevices;
  devices?.length && dispatch(setDeviceForAcc(devices[0]));
};

export const setDevicesFilter = (filter) => (dispatch) => {
  dispatch({ type: ADD_FILTERS, payload: filter });
};

export const setPriceFilter = (filter) => (dispatch) => {
  dispatch({ type: ADD_PRICE_FILTER, payload: filter });
};

export const addDeviceSelection = (device) => (dispatch, getState) => {
  const devices = getState()?.supply?.supplyArticle?.selectedDevices;

  devices.push(device);

  dispatch({
    type: UPDATE_DEVICE_SELECTION,
    payload: [...devices],
  });
};

// If we trigger this with no device it means we are removing all selected devices
export const removeDeviceSelection = (device) => (dispatch, getState) => {
  let devices = getState()?.supply?.supplyArticle?.selectedDevices;
  let accForDevice = getState()?.supply?.supplyArticle?.accForDevice;

  devices = device ? devices.filter((d) => d?.id !== device?.id) : [];

  !!!devices?.length && dispatch(addAccessoriesList([]));

  dispatch({ type: UPDATE_DEVICE_SELECTION, payload: [...devices] });
  if (accForDevice?.id === device?.id) {
    dispatch(setDeviceForAcc(devices?.[0] || {}));
  }
};

export const addAccessoriesSelection = (accessory) => (dispatch, getState) => {
  const device = getState()?.supply?.supplyArticle?.accForDevice;
  let devices = getState()?.supply?.supplyArticle?.selectedDevices;

  devices = devices.map((d) => {
    if (d?.id === device?.id) {
      const updatedDevice = {
        ...d,
        selectedAccessories: [...(d?.selectedAccessories || []), accessory],
      };

      dispatch(setDeviceForAcc(updatedDevice));
      return updatedDevice;
    } else {
      return { ...d };
    }
  });

  dispatch({ type: UPDATE_DEVICE_SELECTION, payload: [...devices] });
};

// If we trigger this with no accessory it means we are removing all selected accessories for that device
export const removeAccessoriesSelection =
  (accessory) => (dispatch, getState) => {
    const device = getState()?.supply?.supplyArticle?.accForDevice;
    let devices = getState()?.supply?.supplyArticle?.selectedDevices;

    devices = devices.map((d) => {
      if (d?.id === device?.id) {
        const updatedDevice = {
          ...d,
          selectedAccessories: accessory
            ? (d?.selectedAccessories || [])?.filter(
                (acc) => acc?.id !== accessory?.id
              )
            : [],
        };
        dispatch(setDeviceForAcc(updatedDevice));
        return updatedDevice;
      } else {
        return { ...d };
      }
    });

    dispatch({ type: UPDATE_DEVICE_SELECTION, payload: [...devices] });
  };

export const changeModalStep = (step) => (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_STEP,
    payload: { activeStep: step },
  });
};

export const setDeviceForAcc = (device) => (dispatch) => {
  dispatch({ type: SELECT_DEVICE_FOR_ACC, payload: device });
};

export const initVariantDetailModal = () => {
  variantDetailModal = new ModalService('device-variant-detail-modal');
};

export const showVariantDetailModal = (content, options) => {
  variantDetailModal?.open(content, options || {});
};

export const closeVariantDetailModal = () => {
  variantDetailModal?.close();
};

export const destroyVariantDetailModal = () => {
  variantDetailModal?.destroy();
};

// image preview modal

export const initImagePreviewModal = () => {
  imagePreviewModal = new ModalService('image-preview-modal');
};

export const showImagePreviewModal = (content, options) => {
  imagePreviewModal?.open(content, options || {});
};

export const closeImagePreviewModal = () => {
  imagePreviewModal?.close();
};

export const destroyImagePreviewModal = () => {
  imagePreviewModal?.destroy();
};
